import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { logEvent } from '../../../utils/firebase'

const Root = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
`

const Package = styled.button`
  flex: 1 1 auto;
  max-width: 196px;

  /*width: 196px;*/
  /*max-width: 25%;*/
  height: 320px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px #ff5e6d4d;
  color: #0c0750;
  font-family: Helvetica Neue;
  font-size: 28px;
  font-weight: 700;
  /*line-height: 27px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  position: relative;
  /*padding: 5px 0 20px;*/
  padding-top: 50px;
  padding-bottom: 24px;
  @media only screen and (max-width: 600px) {
    padding-top: 10px;
    width: 100px;
    height: 170px;
    font-size: 16px;
    min-width: 25%;
    padding: 30px 0 16px;
  }
`

const MainPackage = styled(Package)`
  position: relative;
  /*flex: 1 1 40%;*/
  height: 366px;
  max-width: 282px;
  max-height: 366px;
  /*width: 282px;*/
  /*max-width: 40%;*/
  /*height: 295px;*/

  background-image: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media only screen and (max-width: 600px) {
    width: 150px;
    height: 210px;
    min-width: 30%;
  }
`

const MainPackageContainer = styled.div`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
  box-sizing: border-box;
  font-size: 28px;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    padding-top: 26px;
    padding-bottom: 16px;
  }
`

const Price = styled.span`
  font-family: Helvetica Neue;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`

const MainPrice = styled(Price)`
  font-size: 32px;
`

const Subtitle = styled.span`
  font-family: Helvetica Neue;
  font-size: ${({ isSale }) => (isSale ? '24px' : '20px')};
  font-weight: ${({ isSale }) => (isSale ? '500' : '400')};
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ isSale }) => (isSale ? '#FF5E6D' : '#7e7c8d')};

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`

const Header = styled.span`
  padding-top: 2px;
  box-sizing: border-box;
  height: 40px;
  width: 190px;
  max-width: 90%;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 40px 40px;

  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);

  @media only screen and (max-width: 600px) {
    width: 115px;
    height: 25px;
    font-size: 12px;
  }
`

const Description = styled.span`
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  opacity: 1;
  display: block;
  margin: 40px auto 0;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const CurrentPlan = styled.span`
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  color: #ff5e6d;
  position: ${({ isMain }) => (isMain ? 'static' : 'absolute')};
  top: 5px;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`

const Discount = styled.span`
  display: block;
  height: 15px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ff5e6d;
  margin-bottom: 23px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 17px;
    font-size: 12px;
  }
`
/*
const UpgradeButton = styled.button`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  width: 220px;
  height: 50px;
  border-radius: 25px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border: 0;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 20px auto 0;
  display: block;
`
*/
const SubscribeNow = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  width: 160px;
  height: 40px;
  border-radius: 25px;

  border-radius: 25px;
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  border: 0;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) {
    font-size: 18px;
    width: 190px;
    height: 50px;
    bottom: -25px;
  }
`

const formatPrice = raw => {
  return (raw * 0.01).toFixed(2)
}

const Packages = ({ packages, message, onSelect }) => {
  if (!packages?.length) return null

  const me = useSelector(state => state.profile.me)
  const currentMembershipId = me?.membership?.id
  const isYearly = currentMembershipId === packages[0].id

  const expirationDate = useMemo(() => {
    if (!me?.subscriberRecord?.subscriptions) return null

    return `Expires on ${moment(me?.subscriberRecord?.subscriptions?.[currentMembershipId]?.expires_date).format('MMM D, YYYY')}`
  }, [me?.subscriberRecord?.subscriptions, currentMembershipId])

  //const sorted = [packages[2], packages[0], packages[1]]
  const sorted = [packages[2], packages[0]]

  return (
    <>
      <Root>
        {sorted.map(item => {
          const { id, metadata, name, price } = item
          const isCurrent = currentMembershipId === id
          if (item == 'empty')
            return (
              <Package
                key={`${id}_${name}`}
                onClick={() => {
                  logEvent('secondary_subscription_package_click', {})
                  onSelect(item)
                }}
              ></Package>
            )
          return metadata.promote !== 'true' ? (
            <Package
              key={`${id}_${name}`}
              onClick={() => {
                logEvent('secondary_subscription_package_click', {})
                onSelect(item)
              }}
            >
              {isCurrent && <CurrentPlan>Current Plan</CurrentPlan>}
              <span>
                {!isCurrent && metadata?.offPercent && <Discount>{metadata.offPercent}</Discount>}
                {name}
              </span>
              <Price>${formatPrice(price.unit_amount)}</Price>
              <Subtitle isSale={metadata.convert}>{metadata.convert ? `(${metadata.convert})` : 'Standard'}</Subtitle>
            </Package>
          ) : (
            <MainPackage
              key={`${id}_${name}`}
              onClick={() => {
                logEvent('main_subscription_package_click', {})
                onSelect(item)
              }}
            >
              <MainPackageContainer>
                <Header>{metadata.offPercent}</Header>
                {/* {isCurrent && <CurrentPlan isMain>Current Plan</CurrentPlan>}*/}
                <span>{name}</span>
                <MainPrice>${formatPrice(price.unit_amount)}</MainPrice>
                <Subtitle isSale>({metadata.convert})</Subtitle>
              </MainPackageContainer>

              <SubscribeNow
                onClick={() => {
                  logEvent('main_subscription_package_click', {})
                  onSelect(sorted[1]) // yearly
                }}
              >
                {!currentMembershipId ? 'Subscribe Now' : 'Upgrade'}
              </SubscribeNow>
            </MainPackage>
          )
        })}
      </Root>
      <Description>{currentMembershipId ? expirationDate : message}</Description>
    </>
  )
}

export default Packages
